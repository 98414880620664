import { ApiQueryBase } from '../../../../types/v2/api';
import { api } from './api';

export type GetAllExamsQuery = Omit<ApiQueryBase, 'limit' | 'searchTerm'> & {
  startDate?: string;
  endDate?: string;
  status?: string;
  userName?: string;
  itemsPerPage?: number;
  page?: number;
};

// Fora do padrao de retorno paginado
type GetAllExamsResponse = {
  items: Exam[];
  totalItems: number;
  totalPages: number;
  itemsPerPage: number;
  currentPage: number;
};

export type Exam = {
  _id?: string;
  pendingRequestId: string;
  patientId: string;
  companyId: string;
  status: ExamStatus;
  createdAt: string;
  updatedAt: string;
  name?: string;
  priority?: string;
  rightAcuity?: string;
  leftAcuity?: string;
  documents: Document[];
};

type ExamStatus = 'pending' | 'completed' | 'renewed' | 'rejected';

export type ExamFile = {
  id: string;
  typeId: string;
  date: string;
  name: string;
  path: string;
  year: number;
  patientName: string;
};

export type GetExamsFileResponse = {
  url: string;
  fileName: string;
};

export type GenerateGenericTokenResponse = {
  token: string;
};

export type GenerateGenericTokenBody = {
  priority: 'VERY_HIGH' | 'HIGH' | 'MEDIUM' | 'LOW';
  email?: string | null;
  name?: string | null;
  cpf?: string | null;
  birthdate?: string | null;
  redirectUrl?: string | null;
  prescriptionPath?: string | null;
  partnerParams?: any;
};

export type Document = {
  _id: string;
  name: string;
  path: string;
  type: string;
}

function getAllExamsService(query?: GetAllExamsQuery): Promise<GetAllExamsResponse> {
  return api.get('/v2/exams/renewing', { params: query });
}

function getExamsFileService(documentId: string): Promise<GetExamsFileResponse> {
  return api.get(`/v2/exams/renewing/${documentId}`);
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //         "url": "https://eyecarebi-files-staging.s3.us-east-1.amazonaws.com/0478aac7-182a-4883-95b2-097a55588fdb/459ef40e-a182-4d40-b800-a19a665c4312/567f9b68-6641-4e37-9059-6e0ddca85866-6.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIATZYH5VA3NTA7LXSA%2F20250221%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250221T204625Z&X-Amz-Expires=86400&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELX%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJGMEQCIG8S2p3r2OJfO9Cc8S93PevsE2PBCyT4uaE7baDZ%2FgwQAiAr2iwr8GZfRC38wMWs18SITyqeHJAlyCMAjEfwJuqTqSqHAwje%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAMaDDI2MTQ3Mjc1NzgxNCIMb8fs69QHZ%2Br94HQ%2BKtsCRkhDG63akKLMLA1CQnUTkpCpimviSLJul%2FIEJjsW9JZ2CIvWCj0Xt6%2FRklCf8Ex7%2FYnXPdg%2FdlMOu9PUIliqiCadSU1tk9TcruNiAYdJcAFaQ8EPGfGXfx1e5P0ZKRuPi2wROudHzUqrTyeSjr2v44VL7ME9%2F1845fzUEjvLAns7LoPbwivwumLu8AF7Do8Z9Q0V%2BLJrIiw6EQAr48CakWVvj8pauYePM3Ja2AN9xWiNaKp%2BWNIxqyiinlf3HpV7sVvcoPdToTFN5Z%2FECcv4evZ6TkunVUFiHxRGzlMnQcnDx%2BmfZGs5gcEU1Jy8TgU3xXZRp5Z1vL5SB5VcdAtE5H8qLoJegfYwuDsJ96Z4T0GlXhR9pZFnXoIFE4bhvggYQRmb23KHUBBXmyiBcZ1FnxyHQf%2BtXfWinyg4wPTLb9hs9L0PdaRq8Nfmdmhw9%2F0%2BFWgF5ZXABof6D38wmczjvQY6nwGHgkFL7J6BE1O44uEkZ89aGeyXLnCSSHDwMQHjjnGoEn0W1qM3MfUtJHxPdOKg5cGV1fiG2CJWjWzq%2BsAxAXHeiCxW2tJJrWiOzi6i9ejDtFPcFUONgnNrd1dZK37danBWcdAqHQ2YL%2F%2Fi8e%2BKp6vpFj3%2FPvfmE%2BMAEy%2B7HYI04WfK6S6uyeBNGereBye3EHCUsGxlL3riJsX9efRJT20%3D&X-Amz-Signature=114b6c7f956326a798d76e4963d2a0443ac75a775a1eec6964bf3430e82f588b&X-Amz-SignedHeaders=host&x-id=GetObject",
  //         "fileName": "Prescrição de óculos"          
  //     });
  //   }, 5000);
  // });
}

function generateGenericTokenService(body: GenerateGenericTokenBody): Promise<GenerateGenericTokenResponse> {
  return api.post('/v2/companies/renewal-token', body);
}

export { getAllExamsService, getExamsFileService, generateGenericTokenService };
