import { useEffect, useMemo, useState } from 'react';
import BVStyleguide from '@bv/styleguide';
import BVUtils from '@bv/utils';
import { GlassesIcon, BlendIcon, Braces } from 'lucide-react';
import { addDays, format, subDays } from 'date-fns';
import { ColumnDef, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { ArrowUpDown, SearchIcon, Loader2, RefreshCw } from 'lucide-react';
import {
  Exam,
  Document,
  GetAllExamsQuery,
  getAllExamsService,
  getExamsFileService,
  generateGenericTokenService,
  GenerateGenericTokenBody,
} from '../../services/exam.service';
import { useQuery } from '@tanstack/react-query';
import { GET_ALL_EXAMS } from '@optics-prescriptions/constants/queries';
import { SelectOption } from '../../../../../types/v2/global';
import { debounce } from 'lodash';
import bvUtils from '@bv/utils';
import {
  Badge,
  Popover,
  PopoverContent,
  PopoverTrigger,
  IconButton,
  Input,
  Pagination,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  usePagination,
  Tooltip,
  TooltipProvider,
  TooltipContent,
  TooltipTrigger,
  Button,
} from '@eyecarehealth/ds-aquilae-react';

const EXAM_STATUS_OPTIONS: SelectOption[] = [
  { label: 'Pendente', value: 'pending' },
  { label: 'Realizado', value: 'renewed' },
  { label: 'Rejeitado', value: 'rejected' },
];

const PRIORITY_OPTIONS: SelectOption[] = [
  { label: 'Muito alta', value: 'VERY_HIGH' },
  { label: 'Alta', value: 'HIGH' },
  { label: 'Média', value: 'MEDIUM' },
  { label: 'Baixa', value: 'LOW' },
];

export default function Prescriptions() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { pagination, setPagination } = usePagination();
  const [searchName, setSearchName] = useState<string>('');
  const [rangeDate, setRangeDate] = useState<string[]>([]);
  const [status, setStatus] = useState<string>('');
  const [inputSearchName, setInputSearchName] = useState('');
  const [loadingDocument, setLoadingDocument] = useState<string>();
  const [loadingToken, setLoadingToken] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [priority, setPriority] = useState<string>();
  const [forceLoad, setForceLoad] = useState<string>();

  const decodedToken = BVUtils.token.getDecodedToken();
  const channel = bvUtils.services.pusherService.createChannel(decodedToken.companyId);
  channel.bind('update-pendency', updatePrescriptionFromPusher);

  const mapStatusToVariant: Record<string, string> = {
    pending: 'info',
    rejected: 'destructive',
    renewed: 'success',
  };

  const mapPriorityToVariant: Record<string, string> = {
    VERY_HIGH: 'destructive',
    HIGH: 'warning',
    MEDIUM: 'info',
    LOW: 'success',
  };

  const mapPriorityToDescription: Record<string, string> = {
    VERY_HIGH: 'Muito alta',
    HIGH: 'Alta',
    MEDIUM: 'Média',
    LOW: 'Baixa',
  };

  function updatePrescriptionFromPusher(data: Exam) {
    setForceLoad(data._id);
    BVUtils.toastHandler({ type: 'info', message: `Receita atualizada` });
  }

  function getPrescriptionByType(documents: Document[], type: string): Document | null {
    return documents?.find((document) => document.type === type) || null;
  }

  function handleDateFilter(date: string) {
    switch (date) {
      case '7_dias':
        setRangeDate([subDays(new Date(), 7).toISOString(), new Date().toISOString()]);
        break;

      case '15_dias':
        setRangeDate([subDays(new Date(), 15).toISOString(), new Date().toISOString()]);
        break;

      case '30_dias':
        setRangeDate([subDays(new Date(), 30).toISOString(), new Date().toISOString()]);
        break;

      default:
        setRangeDate([]);
        break;
    }

    setPagination({ ...pagination, pageIndex: 0 });
  }

  function getDocumentIcon(type: string, documents: Document[]) {
    const document = getPrescriptionByType(documents || [], type);
    if (type === '6') {
      if (!document) return <GlassesIcon className="stroke-slate-400" size={20} />;
      if (document._id === loadingDocument) return <Loader2 className="animate-spin" size={20} />;
      return <GlassesIcon className="stroke-blue-500" size={20} />;
    }
    if (type === '13') {
      if (!document) return <BlendIcon className="stroke-slate-400" size={20} />;
      if (document._id === loadingDocument) return <Loader2 className="animate-spin" size={20} />;
      return <BlendIcon className="stroke-blue-500" size={20} />;
    }
  }

  async function fileDownload(patientName: string, document?: Document) {
    if (!document) return;
    setLoadingDocument(document._id);
    try {
      const { url, fileName } = await getExamsFileService(document._id);
      const response = await fetch(url);
      const blob = await response.blob();
      const link = window.document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}_${patientName}`;
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDocument(undefined);
    }
  }

  async function generateGenericToken() {
    if (loadingToken) return;
    if (!priority) {
      BVUtils.toastHandler({ type: 'error', message: `Informe a prioridade` });
      return;
    }
    setLoadingToken(true);
    try {
      const payload: GenerateGenericTokenBody = {
        priority: priority as 'VERY_HIGH' | 'HIGH' | 'MEDIUM' | 'LOW',
        redirectUrl: redirectUrl || null,
      };
      const { token } = await generateGenericTokenService(payload);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const URL = `${window.location.origin}/otica/trocar-receita?token=${token}`;
      // const URL = `https://stage.beneficiovisao.com.br/otica/trocar-receita?token=${token}`;
      navigator.clipboard.writeText(URL);
      BVUtils.toastHandler({ type: 'success', message: `Link copiado para a área de transferência` });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingToken(false);
    }
  }

  const { data: exams, isLoading } = useQuery({
    queryKey: [GET_ALL_EXAMS, pagination.pageIndex, pagination.pageSize, status, searchName, rangeDate, forceLoad],
    queryFn: () => {
      const query: GetAllExamsQuery = {
        page: pagination.pageIndex + 1,
        itemsPerPage: pagination.pageSize,
      };

      if (searchName) {
        query.userName = searchName;
      }

      if (status) {
        query.status = status;
      }

      if (rangeDate && rangeDate.length > 0) {
        query.startDate = rangeDate[0];
        query.endDate = rangeDate[1];
      }

      return getAllExamsService(query);
    },
    retry: 0,
  });

  const columns: ColumnDef<Exam>[] = useMemo(() => {
    return [
      {
        accessorKey: 'createdAt',
        header: ({ column }) => {
          return (
            <div className="flex items-center gap-2">
              Data solicitação
              <IconButton variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                <ArrowUpDown size={14} />
              </IconButton>
            </div>
          );
        },
        cell: ({ row }) => (
          <div className="flex flex-col gap-1">
            <strong className="text-md text-typography">{format(new Date(row.original.createdAt), 'dd/MM/yyyy HH:mm')}</strong>
          </div>
        ),
        enableSorting: true,
      },
      {
        accessorKey: 'name',
        header: ({ column }) => {
          return (
            <div className="flex items-center gap-2">
              Nome
              <IconButton variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                <ArrowUpDown size={14} />
              </IconButton>
            </div>
          );
        },
        cell: ({ row }) => <span>{row.original.name}</span>,
        enableSorting: true,
      },

      {
        accessorKey: 'visualAcuity',
        header: 'Acuidade visual',
        cell: ({ row }) => (
          <div className="flex flex-row gap-1">
            <span>
              <strong>D&nbsp;</strong>
              {row.original.rightAcuity}
            </span>
            <span>
              <strong>E&nbsp;</strong>
              {row.original.leftAcuity}
            </span>
          </div>
        ),
      },
      {
        accessorKey: 'status',
        header: ({ column }) => {
          return (
            <div className="flex items-center gap-2">
              Status
              <IconButton variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
                <ArrowUpDown size={14} />
              </IconButton>
            </div>
          );
        },
        cell: ({ row }) => {
          return <Badge variant={mapStatusToVariant[row.original.status]}>{BVUtils.mapper.mapStatusToDescription[row.original.status]}</Badge>;
        },
        enableSorting: true,
      },
      {
        accessorKey: 'priority',
        header: 'Prioridade',
        cell: ({ row }) => <Badge variant={mapPriorityToVariant[row.original.priority]}>{mapPriorityToDescription[row.original.priority]}</Badge>,
      },
      {
        accessorKey: 'prescriptions',
        header: 'Receitas',
        cell: ({ row }) => (
          <div className="flex flex-row gap-1">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <IconButton
                    variant="ghost"
                    disabled={!getPrescriptionByType(row.original.documents, '6')}
                    onClick={() => {
                      fileDownload(row.original.name, getPrescriptionByType(row.original.documents, '6'));
                    }}>
                    {getDocumentIcon('6', row.original.documents)}
                  </IconButton>
                </TooltipTrigger>
                <TooltipContent>
                  {getPrescriptionByType(row.original.documents, '6') ? (
                    <span className="inline-block max-w-[150px] text-xs">Baixar receita de óculos</span>
                  ) : (
                    <span className="inline-block max-w-[150px] text-xs">Receita de óculos não disponível</span>
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <IconButton
                    variant="ghost"
                    disabled={!getPrescriptionByType(row.original.documents, '13')}
                    onClick={() => {
                      fileDownload(row.original.name, getPrescriptionByType(row.original.documents, '13'));
                    }}>
                    {!getPrescriptionByType(row.original.documents, '13') && <BlendIcon className="stroke-slate-400" size={20} />}
                    {loadingDocument && getPrescriptionByType(row.original.documents, '13')?._id === loadingDocument && (
                      <Loader2 className="animate-spin" size={20} />
                    )}
                    {!loadingDocument && getPrescriptionByType(row.original.documents, '13')?._id !== loadingDocument && (
                      <BlendIcon className="stroke-blue-500" size={20} />
                    )}
                  </IconButton>
                </TooltipTrigger>
                <TooltipContent>
                  {getPrescriptionByType(row.original.documents, '13') ? (
                    <span className="inline-block max-w-[150px] text-xs">Baixar receita de lentes</span>
                  ) : (
                    <span className="inline-block max-w-[150px] text-xs">Receita de lentes não disponível</span>
                  )}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ),
      },
    ];
  }, []);

  const table = useReactTable({
    columns,
    data: exams?.items || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    rowCount: exams?.totalItems || 0,
    pageCount: exams?.totalPages || 0,
    autoResetPageIndex: false,
    manualPagination: true,
    onPaginationChange: setPagination,
    initialState: {
      columnVisibility: {
        email: false,
        id: false,
      },
    },
    state: {
      sorting,
      pagination,
    },
  });

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <div className="mb-2 flex items-center gap-2">
          <div className="relative w-96">
            <SearchIcon className="text-brand-primary absolute left-2 top-1/2 -translate-y-1/2 transform" size={20} />
            <Input
              type="text"
              className="pl-10"
              placeholder="Buscar por nome"
              onChange={(e) => {
                setInputSearchName(e.target.value);
                debounce(() => {
                  setPagination({ ...pagination, pageIndex: 0 });
                  setSearchName(e.target.value);
                }, 2500)();
              }}
              value={inputSearchName}
            />
          </div>
          <div>
            <Select onValueChange={handleDateFilter}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Selecione o período" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value={'7_dias'}>Últimos 7 dias</SelectItem>
                  <SelectItem value={'15_dias'}>Últimos 15 dias</SelectItem>
                  <SelectItem value={'30_dias'}>Últimos 30 dias</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div>
            <Select value={status} onValueChange={setStatus}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Selecione o status" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {EXAM_STATUS_OPTIONS.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>

        <div className="flex flex-row items-center gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Popover>
                  <PopoverTrigger>
                    <IconButton variant="ghost">
                      <Braces size={16} />
                    </IconButton>
                  </PopoverTrigger>
                  <PopoverContent className="w-[300px]">
                    <div className="flex flex-col gap-2">
                      <span className="text-sm font-medium text-slate-500">Gerar link de renovação genérico</span>
                      <div>
                        <span className="text-typography text-sm text-slate-500">Prioridade</span>
                        <Select value={priority} onValueChange={setPriority}>
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Selecionar prioridade" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {PRIORITY_OPTIONS.map((option) => (
                                <SelectItem key={option.value} value={option.value}>
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div>
                        <span className="text-typography text-sm text-slate-500">Redirecionamento (opcional)</span>
                        <Input
                          className="w-full"
                          type="text"
                          placeholder="url de redirecionamento"
                          value={redirectUrl}
                          onChange={(e) => setRedirectUrl(e.target.value)}
                        />
                      </div>
                      <Button className="w-full" loading={loadingToken} onClick={generateGenericToken}>
                        Gerar link genérico
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </TooltipTrigger>
              <TooltipContent>
                <span>Gerar link de renovação genérico</span>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <IconButton variant="ghost" disabled={isLoading} onClick={() => setForceLoad(new Date().toISOString())}>
            {!isLoading && <RefreshCw size={20} />}
            {isLoading && <RefreshCw className="animate-spin" size={20} />}
          </IconButton>
        </div>
      </div>
      <BVStyleguide.TanstackTable table={table} columns={columns} loading={isLoading} />
      <div className="fixed bottom-0 right-0 w-[calc(100%-80px)] bg-white">
        <div className="px-8">
          <Pagination table={table} />
        </div>
      </div>
    </div>
  );
}
