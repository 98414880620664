import BVStyleguide from '@bv/styleguide';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ScrollArea } from '@eyecarehealth/ds-aquilae-react';
import Prescriptions from './pages/Prescriptions';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App(props) {
  return (
    <BVStyleguide.Layout>
      <ScrollArea className="scrollbar-thumb-primary max-w-full flex-1 max-[768px]:px-2 md:px-8">
        <BVStyleguide.Header
          title={`Renovação de receita`}
          description="Aqui você acompanha o status de renovação da receita de seu cliente e faz o download da nova prescrição."
        />
        <QueryClientProvider client={queryClient}>
          <Prescriptions />
        </QueryClientProvider>
      </ScrollArea>
    </BVStyleguide.Layout>
  );
}

